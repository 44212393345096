<template>
    <svg viewBox="0 0 900 30" class="content">
        <text x="0" y="0" alignment-baseline="text-before-edge">{{ getStopTime() }}</text>
    </svg>
</template>

<script>
export default {
    name: "IncomingRegulation",
    props: {
        stop: {
            type: Object,
            default: null,
        },
        forecast: {
            type: Object,
            default: null,
        },
        isActiveRegulation: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        getStopTime() {
            if (this.stop == null)
                return ''

            const arrivalTimeChoice = this.forecast?.predictedArrivalTime ?? this.stop.scheduledArrivalTime
            const departureTimeChoice = this.forecast?.predictedDepartureTime ?? this.stop.scheduledDepartureTime

            const arrivalTime = new Date(new Date(arrivalTimeChoice).toLocaleString('en', { timeZone: 'Europe/Oslo' }))
            const departureTime = new Date(new Date(departureTimeChoice).toLocaleString('en', { timeZone: 'Europe/Oslo' }))
            const currentTime = new Date(new Date().toLocaleString('en', { timeZone: 'Europe/Oslo' }))

            let diff = Math.ceil(((departureTime - arrivalTime) / 1000) / 60)

            if (this.isActiveRegulation && (currentTime >= arrivalTime)) {
                if (currentTime > departureTime) {
                    return 'Bussen kjører straks'
                } else {
                    diff = Math.ceil(((departureTime - currentTime) / 1000) / 60)
                    return `Bussen kjører om ${diff} min`
                }
            }

            return `Venter ved holdeplassen i ${diff} min`
        },
    },        
}
</script>

<style scoped lang="scss">
.content {
    position: absolute;
    overflow: visible;
    font-size: 1vw;
    fill: currentColor;
    white-space: nowrap;
    left: 4vw;
    bottom: 0;
    color: #FFC800;    
}

@media (min-aspect-ratio: 64/27) {
    .content {
        font-size: 0.8vw;
    }
}

@media (min-aspect-ratio: 30/9) {
    .content {
        top: 13vmin;
        left: 5vw;
    }
}
</style>