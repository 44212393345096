<template>
  <div v-if="stopList" class="stop-list-root" :class="{'vessel': vesselDesign}">

    <div style="position: relative;">
      <next-stop v-if="stopList.length > 0"
                 :nextStop="stopList[0]"
                 :isAmplifier="isAmplifier"
                 :activeDate="activeDate"
                 :createdDate="createdDate"
                 :showStopSignal="showStopSignal"
                 :arrival-notice="nextStopArrivalNotice"
                 :vessel-design="vesselDesign"
                 :isRegulationStop="isNextStopRegulation()"
                 class="next-stop faded">
      </next-stop>
      <incoming-regulation v-if="isNextStopRegulation()" :isActiveRegulation="true" :stop="stopList[0]" :forecast="getForecastForStop(stopList[0])"></incoming-regulation>
    </div>

    <div v-for="(item) in stopList.slice(1, 4)" :key="item.stopId" class="stop-list">
      <div class="stop-item faded" :class="{'vessel' : vesselDesign}">
        <div v-if="vesselDesign" class="vertical-line">
          <div class="dot"></div>
        </div>

        <div :class="['name', { regulation: isRegulationStop(item) }]">
          <stop-list-name :name="item.stopName" :vessel-design="vesselDesign"></stop-list-name>
        </div>

        <div v-if="isRegulationStop(item)">
          <incoming-regulation :stop="item" :forecast="getForecastForStop(item)"></incoming-regulation>
        </div>
      </div>
    </div>

    <notification v-if="notification" :msg-type="notification.type" svg-icon>
      <svg-anim v-if="animate" :text="notification.text" @animation-ended="animationEnded"></svg-anim>
    </notification>

  </div>
</template>

<script>
import NextStop from './NextStop'
import Notification from "@/components/Notification"
import SvgAnim from "@/views/demo-screen/slides/animations/SvgAnim"
import StopListName from "@/components/StopListName"
import IncomingRegulation from "@/components/IncomingRegulation"

export default {
  name: 'StopList',
  components: { StopListName, SvgAnim, Notification, NextStop, IncomingRegulation },
  props: {
    stopList: {
      type: Array,
      required: true,
    },
    isAmplifier: {
      type: Boolean,
      required: true,
    },
    activeDate: {
      type: String,
      required: true,
    },
    createdDate: {
      type: String,
      required: true,
    },
    showStopSignal: {
      type: Boolean,
      required: false,
    },
    nextStopArrivalNotice: {
      type: Number,
      required: false,
      default: 0
    },
    vesselDesign: {
      type: Boolean,
      required: false,
    },
    displayRegulationStopInfo: {
      type: Boolean,
      required: false,
      default: false
    },
    notificationHubInfoMessages: {
      type: Array,
      required: false,
      default() { return [] }
    },
    forecasts: {
      type: Object,
      required: false,
      default() { return {} }
    },
  },
  data() {
    return {
      notification: null,
      notifications: [],
      animate: true,
    }
  },
  watch: {
    notificationHubInfoMessages() {
      if (!this.notification) {
        this.notification = this.getNextNotificationHubMessage()
      }
    }
  },
  methods: {
    getNextNotificationHubMessage() {
      if (this.notifications.length === 0) {
        const grouped = this.notificationHubInfoMessages.reduce((c, n) => ({...c, [n.notificationType]: [...(c[n.notificationType] || []), n]}), {})
        for (const notificationType in grouped) {
          this.notifications.push({type: notificationType, text: this.concatMessages(grouped[notificationType])})
        }
      }
      return this.notifications.pop()
    },
    getForecastForStop(item) {
      return (item.sequenceOrder in this.forecasts) ? this.forecasts[item.sequenceOrder] : {}
    },
    concatMessages(messages) {
      return messages.length
          ? messages
              .map(x => x.body)
              .filter(text => text?.trim()?.length > 0)
              .reduce((c, n) => `${c} • ${n}`)
          : ''
    },
    animationEnded() {
      this.animate = false
      this.notification = this.getNextNotificationHubMessage()
      this.$nextTick(() => this.animate = true)
    },
    isNextStopRegulation() {
      if (!this.allowForecastsAndRegulation() || this.stopList.length == 0)
        return false

      const stop = this.stopList[0]
      return stop.isRegulationStop
    },
    isRegulationStop(item) {
      return (this.allowForecastsAndRegulation() && item.isRegulationStop)
    },
    allowForecastsAndRegulation() {
      return !this.vesselDesign && this.displayRegulationStopInfo
    },
  }
}
</script>

<style scoped lang="scss">
.stop-list-root {
  @mixin gradient-before($bg-color: rgb(0, 0, 0), $padding-left: 4.16vw) {
    content: "";
    width: $padding-left;
    position: absolute;
    left: 0;
    top: 1px;
    bottom: 1px;
    background: linear-gradient(to right, rgba($bg-color, 1) 0%, rgba($bg-color, .9) 70%, rgba($bg-color, 0) 100%);
    z-index: 100;
  }

  @mixin gradient-after($bg-color: rgb(0, 0, 0), $width: 4.5vw) {
    content: "";
    background: linear-gradient(to left, rgba($bg-color, 1) 0%, rgba($bg-color, .9) 70%, rgba($bg-color, 0) 100%);
    width: $width;
    position: absolute;
    right: 0;
    top: 1px;
    bottom: 1px;
    z-index: 100;
  }

  .faded::before {
    @include gradient-before();
  }

  .faded::after {
    @include gradient-after();
  }

  &.vessel {
    .faded::before {
      @include gradient-before(rgb(0, 0, 0), 15vw);
    }

    .next-stop.faded::before {
      @include gradient-before(rgb(51, 63, 73), 15vw); // #333F48
    }

    .next-stop.faded::after {
      @include gradient-after(rgb(51, 63, 73));
    }
  }

  .stop-list {
    flex: 1 1 auto;
    font-size: 5.8vw;
    font-weight: 400;
    color: #cccccc;
    line-height: 13.8vmin;
    white-space: nowrap;

    .stop-item {
      padding: 2.4vmin 0;
      border-top: thin solid #cccccc;
      position: relative;
    }

    .vertical-line {
      position: absolute;
      top: 0;
      left: 7.8vw;
      height: 100%;
      border-right: 20px solid $vessel-green-line;
      z-index: 200;
    }

    .vertical-line .dot {
      box-sizing: border-box;
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto 0;
      left: -28px;
      width: 75px;
      height: 75px;
      background: #FFFFFF;
      border: 12px solid $vessel-green-line;
      border-radius: 50%;
      z-index: 200;
    }

    .name {
      overflow: hidden;
      position: relative;
    }

    .regulation {
      font-size: 4.75vw;
    }
  }

  @media (min-aspect-ratio: 64/27) {
    .stop-list {
      font-size: 4.65vw;

      .regulation {
        font-size: 3vw;
      }
    }
  }

  @media (min-aspect-ratio: 30/9) {
    .stop-list {
      font-size: 3.26vw;

      .regulation {
        font-size: 2.25vw;
      }
    }
  }
}
</style>